/* @keyframes rotate {
  from {
    transform: rotateX(-30deg) rotateY(-30deg);
  }

  to {
    transform: rotateX(-30deg) rotateY(330deg);
  }
}

.perspective-600 {
  perspective: 600px;
}

.preserve-3d {
  transform-style: preserve-3d;
}

.animate-rotate {
  animation: rotate 10s infinite linear;
}

.transform-3d-front {
  transform: translateZ(100px);
}

.transform-3d-back {
  transform: rotateY(180deg) translateZ(100px);
}

.transform-3d-right {
  transform: rotateY(90deg) translateZ(100px);
}

.transform-3d-left {
  transform: rotateY(-90deg) translateZ(100px);
}

.transform-3d-top {
  transform: rotateX(90deg) translateZ(100px);
}

.transform-3d-bottom {
  transform: rotateX(-90deg) translateZ(100px);
}

.arestas {
  position: absolute;
  background-color: red;
  z-index: 1;
}

.label {
  position: absolute;
  background-color: yellow;
  padding: 2px 4px;
  font-size: 12px;
  z-index: 2;
}

.label-depth {
  top: 50%;
  left: 0;
  transform: translateX(-50%) translateY(-50%);
}

.label-width {
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.label-height {
  top: 50%;
  right: 0;
  transform: translateX(50%) translateY(-50%);
}

.depth-edge {
  width: 2px;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.width-edge {
  width: 100%;
  height: 2px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.height-edge {
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  transform: translateX(50%);
} */



@keyframes rotate {
  from {
    transform: rotateX(-30deg) rotateY(-30deg);
  }

  to {
    transform: rotateX(-30deg) rotateY(330deg);
  }
}

.perspective-600 {
  perspective: 600px;
}

.preserve-3d {
  transform-style: preserve-3d;
}

.animate-rotate {
  animation: rotate 10s infinite linear;
}

.transform-3d-front {
  transform: translateZ(100px);
}

.transform-3d-back {
  transform: rotateY(180deg) translateZ(100px);
}

.transform-3d-right {
  transform: rotateY(90deg) translateZ(100px);
}

.transform-3d-left {
  transform: rotateY(-90deg) translateZ(100px);
}

.transform-3d-top {
  transform: rotateX(90deg) translateZ(100px);
}

.transform-3d-bottom {
  transform: rotateX(-90deg) translateZ(100px);
}

.arestas {
  position: absolute;
  background-color: red;
  z-index: 1;
}

.label {
  position: absolute;
  background-color: yellow;
  padding: 2px 4px;
  font-size: 12px;
  z-index: 2;
}

.label-depth {
  top: 50%;
  left: 0;
  transform: translateX(-50%) translateY(-50%);
}

.label-width {
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.label-height {
  top: 50%;
  right: 0;
  transform: translateX(50%) translateY(-50%);
}

.depth-edge {
  width: 2px;
  height: 200%;
  top: -100%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.width-edge {
  width: 200%;
  height: 2px;
  top: 50%;
  left: -100%;
  transform: translateY(-50%) translateX(-50%);
}

.height-edge {
  width: 2px;
  height: 200%;
  top: -100%;
  right: -100%;
  transform: translateX(50%) translateY(-50%);
}